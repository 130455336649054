.App {
  text-align: center;
  min-height: 100vh;
  width: 100%;
  display: flex;
  background: $grey1;
  font-family: "europa", sans-serif;
  padding: 70px 24px;
  flex-direction: column;

  .logo {
    margin-bottom: 40px;
    margin-top: -17px;
  }

  .hv-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    margin-bottom: 1rem;
    margin-top: 0;
    text-align: center;
    font-size: 2.25rem;
    line-height: 1.25;
    color: $black;
    font-weight: 700;
  }

  .btn-primary {
    width: 100%;
    display: block;
    background-color: #632adb;
    border: transparent;
    border-radius: 0.1875rem;
    color: #ffffff;
    cursor: pointer;
    font-family: "Europa", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.125rem;
    outline: none;
    overflow-wrap: break-word;
    padding: 0.9375rem 1.25rem;
    position: relative;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 7px 25px rgba(99, 42, 219, 0.3);

    &:hover {
      background-color: #451d99;
      box-shadow: 0 0.25rem 0.75rem rgba(69, 29, 153, 0.3);
      -webkit-transform: translateY(0.125rem);
      transform: translateY(0.125rem);
    }
  }

  .redirectText {
    color: $purple;
    text-decoration: underline;
    cursor: pointer;
  }

  .error-message {
    color: #df2a4a;
  }

  .close {
    margin-left: 10px;
  }

  a {
    color: $purple;
    text-decoration: underline;
    transition: 0.3s;

    &:hover {
      color: $black;
    }
  }
}
