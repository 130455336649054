@import "../../styles/global/variables";

.Welcome {
  width: 100%;
  max-width: 1110px;

  > .desc {
    max-width: 521px;
    margin: 0 auto 72px;
    font-size: 17px;
    line-height: 27px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__wrapper {
    position: relative;

    @media (max-width: 768px) {
      padding: 2rem 1.5rem;
    }

    &.Welcome-faq-open {
      margin-bottom: 20px;
    }

    &__avatar {
      position: absolute;
      height: 82px;
      width: 82px;
      top: -41px;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 41px;

      img {
        width: 100%;
        height: auto;
        border: 4px solid white;
        border-radius: 50%;
      }
    }

    &__top {
      h2 {
        margin-bottom: 2rem;
      }

      p {
        max-width: 558px;
        margin: auto;
      }
    }

    &__bottom {
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }

      .col {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        border-radius: 5px;
        box-shadow: 0px 40px 80px rgba(69, 63, 94, 0.1);
        padding: 24px;

        &:first-child {
          margin-right: 30px;

          @media (max-width: 768px) {
            margin-bottom: 24px;
            margin-right: 0;
          }
        }

        .title-image {
          margin-bottom: 40px;

          @media (max-width: 480px) {
            margin-bottom: 0;
          }
        }

        .btn {
          width: 100%;
          max-width: 324px;
        }

        h3 {
          font-weight: bold;
          font-size: 28px;
          line-height: 35px;
          text-align: center;
          color: $black;
          margin-bottom: 5px;
          margin-top: 22px;
        }

        p {
          color: $black;
          font-size: 17px;
          margin-bottom: 32px;
        }

        .image {
          @media (max-width: 480px) {
            width: 87.02%;
            margin: 0 auto;
          }
        }

        img {
          max-width: 100%;
        }

        .create-acc {
          margin: 24px auto 0;

          @media (max-width: 768px) {
            max-width: 220px;
          }
        }

        .plugin-info {
          font-size: 15px;
          line-height: 24px;
          margin-bottom: 20px;
        }

        .btn-text-wrapper {
          min-height: 97px;
          width: 100%;

          @media (max-width: 480px) {
            min-height: unset;
          }

          button {
            margin: 0 auto;

            @media (max-width: 480px) {
              margin-top: -5px;
            }
          }
        }
      }
    }
  }

  &__faq {
    &__button {
      color: white;
      margin-top: 66px;
      text-decoration: underline;
      cursor: pointer;
      display: block;
    }
  }

  .contact-us {
    margin-top: 76px;
    font-size: 17px;
    line-height: 27px;

    @media (max-width: 768px) {
      margin-top: 0;
    }
  }
}
